<template>
  <svg
    width="125px"
    height="32px"
    viewBox="0 0 125 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="addPalyer"
    @click="choosePlayer()"
  >
    <title>Card player/add player</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Card-player/complete" transform="translate(-48.000000, -89.000000)">
        <g id="Group" transform="translate(48.000000, 89.000000)">
          <path
            d="M116.631948,31 C117.280569,31 117.843373,30.5429111 117.984074,29.9007618 L123.966356,2.71003843 C124.158946,1.83327715 123.500346,1 122.613232,1 L8.50881323,1 C7.74044674,1 7.07585962,1.54001214 6.90821603,2.30047866 L1.03969224,28.9774826 C0.812175934,30.015034 1.59151909,31 2.64128732,31 L116.631948,31 Z"
            id="Stroke-1"
            :stroke="color"
          />
          <text
            id="ADD-PLAYER"
            fill-rule="nonzero"
            font-family="Helvetica"
            font-size="12"
            font-weight="normal"
          >
            <tspan x="23.9873047" y="21">ADD PLAYER</tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  methods: {
    choosePlayer() {
      this.$store.commit("player", true);
      this.$store.commit("playerId", this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
svg {
  margin: 0 auto;
  display: block;
}

text {
  fill: var(--hb-fill);
}
</style>