var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "svgCard",
      attrs: {
        width: "220px",
        height: "335px",
        viewBox: "0 0 220 335",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("title", [_vm._v("Group")]),
      _c(
        "g",
        {
          attrs: {
            id: "Symbols",
            stroke: "none",
            "stroke-width": "2",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              attrs: { id: "Group", transform: "translate(1.000000, 1.000000)" }
            },
            [
              _c("polygon", {
                attrs: {
                  id: "Path",
                  stroke: _vm.color,
                  points: "0 332.97 0 0 180.98 0.01 218 38.4 218 333"
                }
              }),
              _c(
                "g",
                {
                  attrs: {
                    id: "Campo-calcio",
                    transform: "translate(0.000000, 187.000000)",
                    "fill-rule": "nonzero"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M217.502528,145.73 L0.496001854,145.73 L0.496001854,0 L217.502528,0 L217.502528,145.73 Z M1.30600185,144.92 L216.692528,144.92 L216.692528,0.81 L1.30600185,0.81 L1.30600185,144.92 Z",
                      id: "Shape"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      x: "108.14",
                      y: "0.41",
                      width: "1",
                      height: "144.92"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M108.54,94.61 C96.5333295,94.61 86.8,84.8766705 86.8,72.87 C86.8,60.8633295 96.5333295,51.13 108.54,51.13 C120.54667,51.13 130.28,60.8633295 130.28,72.87 C130.263475,84.869819 120.539819,94.5934751 108.54,94.61 L108.54,94.61 Z M108.54,51.94 C96.9806802,51.94 87.61,61.3106802 87.61,72.87 C87.61,84.4293198 96.9806802,93.8 108.54,93.8 C120.09932,93.8 129.47,84.4293198 129.47,72.87 C129.458974,61.3152502 120.09475,51.9510255 108.54,51.94 L108.54,51.94 Z",
                      id: "Shape"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M33.18,119.25 L0.496001854,119.25 L0.496001854,26.48 L33.18,26.48 L33.18,119.25 Z M1.30600185,118.44 L32.37,118.44 L32.37,27.29 L1.30600185,27.29 L1.30600185,118.44 Z",
                      id: "Shape"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M217.502528,119.25 L183.91,119.25 L183.91,26.48 L217.502528,26.48 L217.502528,119.25 Z M184.72,118.44 L216.692528,118.44 L216.692528,27.29 L184.72,27.29 L184.72,118.44 Z",
                      id: "Shape"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M14.05,97.18 L0.496001854,97.18 L0.496001854,48.55 L14.05,48.55 L14.05,97.18 Z M1.30600185,96.37 L13.24,96.37 L13.24,49.37 L1.30600185,49.37 L1.30600185,96.37 Z",
                      id: "Shape"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M217.502528,97.18 L203.412528,97.18 L203.412528,48.55 L217.462528,48.55 L217.502528,97.18 Z M204.262528,96.37 L216.692528,96.37 L216.692528,49.37 L204.262528,49.37 L204.262528,96.37 Z",
                      id: "Shape"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M32.77,91.66 L32.37,91.66 L32.37,54.07 L32.77,54.07 C38.68,54.07 43.48,62.5 43.48,72.87 C43.48,83.24 38.68,91.66 32.77,91.66 Z M33.18,54.9 L33.18,90.84 C38.45,90.45 42.67,82.54 42.67,72.84 C42.67,63.14 38.45,55.28 33.18,54.9 Z",
                      id: "Shape"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M184.72,91.66 L184.31,91.66 C178.41,91.66 173.61,83.23 173.61,72.87 C173.61,62.51 178.41,54.07 184.31,54.07 L184.72,54.07 L184.72,91.66 Z M183.91,54.9 C178.64,55.28 174.42,63.2 174.42,72.9 C174.42,82.6 178.64,90.48 183.91,90.9 L183.91,54.9 Z",
                      id: "Shape"
                    }
                  }),
                  _c("circle", {
                    attrs: { id: "Oval", cx: "108.54", cy: "72.87", r: "2.94" }
                  }),
                  _c("circle", {
                    attrs: { id: "Oval", cx: "23.21", cy: "72.87", r: "1.47" }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M192.41,72.87 C192.41,72.0581414 193.068141,71.4 193.88,71.4 C194.691859,71.4 195.35,72.0581414 195.35,72.87 C195.35,73.6818586 194.691859,74.34 193.88,74.34 C193.068141,74.34 192.41,73.6818586 192.41,72.87 L192.41,72.87 Z",
                      id: "Path"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M0.906001854,6.33 L0.496001854,6.33 L0.496001854,0 L6.38600185,0 L6.38600185,0.36 C6.4332545,0.493604464 6.47003232,0.630685433 6.49600185,0.77 C6.49600185,2.24981626 5.90612301,3.66858949 4.8569233,4.71215843 C3.80772359,5.75572738 2.38579658,6.33798462 0.906001854,6.33 Z M1.30600185,0.81 L1.30600185,5.5 C3.74516407,5.28877084 5.62420511,3.25819423 5.64600185,0.81 L1.30600185,0.81 Z",
                      id: "Shape"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M217.502528,6.33 L217.092528,6.33 C214.025731,6.32448417 211.542528,3.8368022 211.542528,0.77 C211.542528,0.63 211.542528,0.49 211.542528,0.36 L211.542528,4.4408921e-15 L217.432528,4.4408921e-15 L217.502528,6.33 Z M212.352528,0.81 C212.374325,3.25819423 214.253366,5.28877084 216.692528,5.5 L216.692528,0.81 L212.352528,0.81 Z",
                      id: "Shape"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M6.38600185,145.73 L0.496001854,145.73 L0.496001854,139.4 L0.906001854,139.4 C2.39193338,139.394643 3.81840609,139.983243 4.86818577,141.0349 C5.91796546,142.086558 6.50401478,143.51408 6.49600185,145 C6.50581389,145.139828 6.50581389,145.280172 6.49600185,145.42 L6.38600185,145.73 Z M1.30600185,144.92 L5.64600185,144.92 C5.62420511,142.471806 3.74516407,140.441229 1.30600185,140.23 L1.30600185,144.92 Z",
                      id: "Shape"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M217.502528,145.73 L211.612528,145.73 L211.612528,145.38 C211.612528,145.24 211.612528,145.1 211.612528,144.96 C211.618011,141.895471 214.098014,139.410999 217.162528,139.4 L217.572528,139.4 L217.502528,145.73 Z M216.692528,144.92 L216.692528,140.23 C214.253366,140.441229 212.374325,142.471806 212.352528,144.92 L216.692528,144.92 Z",
                      id: "Shape"
                    }
                  })
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }