<template>
  <div class="Select" ref="fullpanel">
    <div class="Select_box" ref="insidepanel">
     <img  v-if="appStyle == 'stats'"  
        @click="closePanel"
        class="setparameters_box:close"
        src="~@/assets/images/general/x_stats.svg"
        alt
      />
      <img  v-else  
        @click="closePanel"
        class="setparameters_box:close"
        src="~@/assets/images/general/x.svg"
        alt
      />
       <div class="Select_title">Select Player</div>
      <div class="Select_container">
        <div>
          <div class="Select_container:title">Home</div>
          <div
            class="Select_container:details"
            v-for="(val, index) in home"
            :key="index"
            @click="getPlayer(val.jerseyNum, val.isOppositeTeam)"
          >{{val.jerseyNum}} {{val.name}}</div>
           <div class="Select_container:details" @click="getPlayer('T', false)">Team</div>
       </div>
        <div>
          <div class="Select_container:title">Away</div>
          <div
            class="Select_container:details"
            v-for="(val, index) in away"
            :key="index"
            @click="getPlayer(val.jerseyNum, val.isOppositeTeam)"
          >{{val.jerseyNum}} {{val.name}}</div>
           <div class="Select_container:details" @click="getPlayer('T', true)">Team</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TweenMax } from "gsap";
import axios from "axios";
export default {
  data() {
    return {
      home: [],
      away: [],
    };
  },
  props: {
    players: {
      type: Array,
      required: true,
    },
  },
  watch: {
    player(val) {
      if (val) {
        this.openPanel();
      }
    },
    // players(val) {
    //   const awayPlayers = val.filter((e) => {
    //     if (e.isOppositeTeam == true) {
    //       return e;
    //     }
    //   });
    //   this.away = awayPlayers;
    //   const homePlayers = val.filter((e) => {
    //     if (e.isOppositeTeam == false) {
    //       return e;
    //     }
    //   });
    //   this.home = homePlayers;
    // },
  },
  methods: {
    getPlayer(number, team) {
      this.$store.dispatch("fetchPlayer", {
        id: this.playerId,
        number: number,
        team: team,
        idGame: this.idgame != null ? this.idgame : this.storeIdgame ,
      });
      this.closePanel();
    },
    openPanel() {
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 1,
        scale: 1,
        delay: 0.1,
      });
    },
    closePanel() {
      this.$store.commit("player", false);
      const fullpanel = this.$refs.fullpanel;
      const insidepanel = this.$refs.insidepanel;
      TweenMax.to(fullpanel, { duration: 0.3, autoAlpha: 0, delay: 0.1 });
      TweenMax.to(insidepanel, {
        duration: 0.3,
        autoAlpha: 0,
        scale: 0.9,
      });
    },
    fetchData() {
      // Prendo storeIdGame e idGame è nullo (Offline)
      let urlIdGame = this.idgame != null ? this.idgame : this.storeIdgame;
      axios
        .get(process.env.VUE_APP_API_URL + "/liveactiveplayers/" + urlIdGame)
        .then((response) => {
          if (response.data) {
            this.home = response.data.filter(x => x.home_away == 'H').map((e) => {
                  return {
                    jerseyNum: e.jersey_num,
                    isOppositeTeam: false,
                    name: e.nickname
                    }
                });
            this.away = response.data.filter(x => x.home_away == 'A').map((e) => {
                  return {
                    jerseyNum: e.jersey_num,
                    isOppositeTeam: true,
                    name: e.nickname
                    }
                });
          } 
        })
        .catch((err) => {
          console.log("liveactiveplayers error ==> " + err);
        });
    },
  },
  computed: {
    player() {
      return this.$store.state.faceto.player;
    },
    playerId() {
      return this.$store.state.faceto.playerId;
    },
    idgame() {
      return this.$store.state.idgame;
    },
    storeIdgame() {
      return this.$store.state.storeIdgame;
    },
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scope>
.Select {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--bg-panel-color);
  visibility: hidden;
  text-align: center;
  z-index: 1200;
  &_box {
    color: var(--color);
    width: auto;
    display: inline-block;
    padding: 30px;
    background-image: var(--background-image);
    border: var(--border);
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.9);
    position: absolute;
    &\:close {
      padding: 20px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  &_title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &_container {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 650px;
    &\:title {
      padding: 10px 0;
      border-bottom: var(--table-border-bottom);
      font-weight: bold;
      margin-bottom: 20px;
    }
    &\:details {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      position: relative;
      width: 180px;
      padding: 7px 15px;
      position: relative;
      margin: 15px 0 15px 15px;
      border-left: 0;
      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 10px;
        height: 100%;
        top: 0px;
        left: -10px;

        border: var(--player-border);
        border-right: 0px;
        background-color: var(--selectplayers-bg-color);

        transform-origin: bottom left;
        -ms-transform: skew(-10deg, 0deg);
        -webkit-transform: skew(-10deg, 0deg);
        transform: skew(-10deg, 0deg);
      }
      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0px;
        right: 0px;

        border: var(--player-border);
        border-left: 0px;

        transform-origin: bottom left;
        -ms-transform: skew(-10deg, 0deg);
        -webkit-transform: skew(-10deg, 0deg);
        transform: skew(-10deg, 0deg);
      }
      &\:number {
        padding: 0;
        padding-right: 10px;
        padding-left: 2px;
      }
      &\:name {
        width: 130px;
        position: absolute;
        left: 40px;
      }
    }
  }
  &_text {
    width: 650px;
    text-align: center;
  }
}
</style>