<template>
  <div class="CardPlayer">
    <div class="CardPlayer_Name" v-if="player.name != ''">
      <div class="CardPlayer_Name_number" v-if="player.jerseyNum != 'T'" >{{ player.jerseyNum }}</div>
      <div class="CardPlayer_Name_name" v-if="player.jerseyNum != 'T'">{{ player.name }}</div>
      <div class="CardPlayer_Name_name" v-if="(player.jerseyNum == 'T' && player.isOppositeTeam == 'false')">Home Team</div>
      <div class="CardPlayer_Name_name" v-if="(player.jerseyNum == 'T' && player.isOppositeTeam == 'true')">Away Team</div>
    </div>
    <div class="CardPlayer">
      <div v-if="player.name" class="CardPlayer_graphic">
        <img
          v-if="color == '#00FC77'"
          src="~@/assets/images/faceto/greenGraphic.png"
          alt
        />
        <img
          v-else-if="color == '#ff2858'"
          src="~@/assets/images/faceto/redGraphic.png"
          alt
        />
        <img
          v-if="color == '#00ffff'"
          src="~@/assets/images/faceto/blueGraphic.png"
          alt
        />
        <img
          v-else-if="color == '#ff00ff'"
          src="~@/assets/images/faceto/purpleGraphic.png"
          alt
        />
      </div>
      <img
        v-if="player.name && appStyle == 'stats'"
        @click="deletePalyer()"
        class="CardPlayer_Player:close"
        src="~@/assets/images/general/x_stats.svg"
        alt
      />
      <img
        v-if="player.name && appStyle != 'stats'"
        @click="deletePalyer()"
        class="CardPlayer_Player:close"
        src="~@/assets/images/general/x.svg"
        alt
      />
     <img 
        v-if="player.name"
        class="CardPlayer_Player:image"
        src="~@/assets/images/general/card/photoBW.png"
        alt
      />
      <!--img v-if="player.name" class="CardPlayer_Player:image" :src="pictureURL" /-->
      <div class="CardPlayer_Player_addPlayer" v-if="!player.name">
        <AddPlayer :color="color" :id="id" />
      </div>
      <div class="CardPlayer_Player:heatmap">
        <Heatmap
          v-if="player.name"
          :id="id"
          :color="color"
          :data="player.heatmap"
        />
        <div class="heatmap" v-else></div>
      </div>
      <Card :color="color" />
    </div>
  </div>
</template>

<script>
import Card from "@/components/faceto/cardplayer/Card.vue";
import AddPlayer from "@/components/faceto/cardplayer/AddPlayer.vue";
import Heatmap from "@/components/faceto/cardplayer/Heatmap.vue";
export default {
  data() {
    return {
      playerBase: {
        jerseyNum: "",
        isOppositeTeam: "",
        name: "",
        heatmap: [],
      },
    };
  },
  components: {
    Card,
    AddPlayer,
    Heatmap,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  watch: {},
  computed: {
    player() {
      return this.$store.state.faceto.players[this.id];
    },
    playerArray() {
      return this.$store.state.faceto.players;
    },
    idGame() {
      return this.$store.state.idgame;
    },
    pictureURL() {
      const idGame = this.$store.state.idgame;
      const remainder = idGame % 1000;
      const livePort = 16000 + remainder;
      const url =
        "http://" +
        process.env.VUE_APP_LIVES_WS_SERVER +
        ":" +
        livePort +
        "/pictures/" +
        this.id +
        ".png";
      return url;
    },
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
  methods: {
    deletePalyer() {
      const player = this.playerBase;
      const id = this.id;
      this.$store.commit("addPlayer", { player, id });
    },
  },
};
</script>

<style lang="scss" scoped>
.CardPlayer {
  display: inline-block;
  position: relative;
  &_graphic {
    position: absolute;
    top: 33px;
  }
  &_Name {
    display: inline-block;
    background-color: #fff;
    color: black;
    display: inline-flex;
    padding: 4px 30px 4px 10px;
    font-size: 13px;
    position: absolute;
    font-weight: bold;
    z-index: 120;
    top: 20px;
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 30px;
      height: 21px;
      top: 0px;
      right: -30px;

      border: var(--player-border);
      border-right: 0px;
      background-color: white;

      transform-origin: bottom right;
      transform: skew(30deg, 0deg);
    }
    &_number {
      padding-right: 10px;
    }
    &_name {
    }
  }
  &_Player {
    position: relative;
    display: block;
    height: 373px;
    &_addPlayer {
      position: absolute;
      top: 110px;
      display: block;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 200;
    }
    &\:image {
      position: absolute;
      object-fit: cover;
      margin-top: 34px;
      margin-left: -0.5px;
      width: 218px;
      height: 186px;
      mask: url("~@/assets/images/faceto/card.svg");
    }
    &\:close {
      width: 12px;
      height: 12px;
      position: absolute;
      right: 0px;
      top: 32px;
      z-index: 200;
    }
    &\:heatmap {
      position: absolute;
      bottom: 39px;
      left: 2px;
      z-index: 0;
    }
  }
}
</style>
