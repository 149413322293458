var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "facetoface" },
      [
        _c("Header"),
        _c("div", { staticClass: "facetoface_container" }, [
          _c(
            "div",
            { staticClass: "facetoface_container:left" },
            [
              _c("CardPlayer", { attrs: { id: 0, color: "#00FC77" } }),
              _c("CardPlayer", { attrs: { id: 1, color: "#ff2858" } })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "facetoface_container:graph" },
            [
              _vm.appStyle != "stats"
                ? _c("img", {
                    staticClass: "facetoface_container:graph_data",
                    attrs: {
                      src: require("@/assets/images/faceto/data.png"),
                      alt: ""
                    }
                  })
                : _vm._e(),
              _vm.radar
                ? _c("Radar", {
                    key: _vm.count,
                    attrs: { paramdata: _vm.playersdataRadar }
                  })
                : _vm._e(),
              !_vm.radar
                ? _c("TemporalGraph", {
                    key: _vm.count,
                    staticClass: "facetoface_temporal",
                    attrs: { paramdata: _vm.playersdataTemp }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "facetoface_container:right" },
            [
              _c("CardPlayer", { attrs: { id: 2, color: "#00ffff" } }),
              _c("CardPlayer", { attrs: { id: 3, color: "#ff00ff" } })
            ],
            1
          )
        ]),
        _c("SelectPlayer", { attrs: { players: _vm.players, multiple: true } }),
        _c("SelectGraphParameter"),
        _c("Connection"),
        _c("Menu")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }