var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CardPlayer" }, [
    _vm.player.name != ""
      ? _c("div", { staticClass: "CardPlayer_Name" }, [
          _vm.player.jerseyNum != "T"
            ? _c("div", { staticClass: "CardPlayer_Name_number" }, [
                _vm._v(_vm._s(_vm.player.jerseyNum))
              ])
            : _vm._e(),
          _vm.player.jerseyNum != "T"
            ? _c("div", { staticClass: "CardPlayer_Name_name" }, [
                _vm._v(_vm._s(_vm.player.name))
              ])
            : _vm._e(),
          _vm.player.jerseyNum == "T" && _vm.player.isOppositeTeam == "false"
            ? _c("div", { staticClass: "CardPlayer_Name_name" }, [
                _vm._v("Home Team")
              ])
            : _vm._e(),
          _vm.player.jerseyNum == "T" && _vm.player.isOppositeTeam == "true"
            ? _c("div", { staticClass: "CardPlayer_Name_name" }, [
                _vm._v("Away Team")
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "CardPlayer" },
      [
        _vm.player.name
          ? _c("div", { staticClass: "CardPlayer_graphic" }, [
              _vm.color == "#00FC77"
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/faceto/greenGraphic.png"),
                      alt: ""
                    }
                  })
                : _vm.color == "#ff2858"
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/faceto/redGraphic.png"),
                      alt: ""
                    }
                  })
                : _vm._e(),
              _vm.color == "#00ffff"
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/faceto/blueGraphic.png"),
                      alt: ""
                    }
                  })
                : _vm.color == "#ff00ff"
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/faceto/purpleGraphic.png"),
                      alt: ""
                    }
                  })
                : _vm._e()
            ])
          : _vm._e(),
        _vm.player.name && _vm.appStyle == "stats"
          ? _c("img", {
              staticClass: "CardPlayer_Player:close",
              attrs: {
                src: require("@/assets/images/general/x_stats.svg"),
                alt: ""
              },
              on: {
                click: function($event) {
                  return _vm.deletePalyer()
                }
              }
            })
          : _vm._e(),
        _vm.player.name && _vm.appStyle != "stats"
          ? _c("img", {
              staticClass: "CardPlayer_Player:close",
              attrs: { src: require("@/assets/images/general/x.svg"), alt: "" },
              on: {
                click: function($event) {
                  return _vm.deletePalyer()
                }
              }
            })
          : _vm._e(),
        _vm.player.name
          ? _c("img", {
              staticClass: "CardPlayer_Player:image",
              attrs: {
                src: require("@/assets/images/general/card/photoBW.png"),
                alt: ""
              }
            })
          : _vm._e(),
        !_vm.player.name
          ? _c(
              "div",
              { staticClass: "CardPlayer_Player_addPlayer" },
              [_c("AddPlayer", { attrs: { color: _vm.color, id: _vm.id } })],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "CardPlayer_Player:heatmap" },
          [
            _vm.player.name
              ? _c("Heatmap", {
                  attrs: {
                    id: _vm.id,
                    color: _vm.color,
                    data: _vm.player.heatmap
                  }
                })
              : _c("div", { staticClass: "heatmap" })
          ],
          1
        ),
        _c("Card", { attrs: { color: _vm.color } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }