<template>
  <div>
    <div class="heatmap" style="height: 143px; width: 216px"></div>
  </div>
</template>

<script>
import Heatmap from "heatmap.js";
export default {
  data() {
    return {
      colors: [
        {
          ".2": "green",
          ".8": "yellow",
          ".95": "white"
        },
        {
          ".2": "red",
          ".8": "pink",
          ".95": "white"
        },
        {
          ".2": "blue",
          ".8": "lightblue",
          ".95": "white"
        },
        {
          ".2": "DarkViolet",
          ".8": "purple",
          ".95": "white"
        }
      ]
    };
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: false
    },
    data: {
      type: Array,
      require: true
    }
  },
  methods: {
    heatmap() {
      // customized heatmap configuration
      let colors = this.colors[this.id];
      var heatmapInstance = Heatmap.create({
        // required container
        container: document.getElementsByClassName("heatmap")[this.id],
        // backgroundColor to cover transparent areas
        backgroundColor: "rgba(0,0,0,0)",
        // custom gradient colors
        gradient:
          // enter n keys between 0 and 1 here
          // for gradient color customization
          colors,
        // the maximum opacity (the value with the highest intensity will have it)
        maxOpacity: 0.9,
        // minimum opacity. any value > 0 will produce
        // no transparent gradient transition
        minOpacity: 0.1
      });

      // now generate some random data
      var points = this.data;
      var max = 0;
      var width = 216;
      var height = 143;
      var len = 10;

      const calcMax = Math.max(
        ...points.map(function(val, index) {
          return val.value;
        })
      );

      // while (len--) {
      //   var val = Math.floor(Math.random() * 20);
      //   var radius = Math.floor(Math.random() * 60);

      //   max = Math.max(max, val);
      //   var point = {
      //     x: Math.floor(Math.random() * width),
      //     y: Math.floor(Math.random() * height),
      //     value: val
      //     // radius: radius
      //   };
      //   points.push(point);
      // }
      // heatmap data format
      var data = {
        max: calcMax,
        data: points
      };
      // if you have a set of datapoints always use setData instead of addData
      // for data initialization
      heatmapInstance.setData(data);
    }
  },
  mounted() {
    this.heatmap();
  }
};
</script>

<style>
</style>