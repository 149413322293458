<template>
  <div class="Radar"></div>
</template>

<script>
import * as d3 from "d3";
import axios from "axios";
export default {
  data() {
    return {
      dataradar: "",
    };
  },
  mounted() {
    console.log(this.paramdata);
    this.fetchData();
  },
  props: {
    paramdata: {
      type: Array,
    },
  },
  computed: {
    idgame() {
      return this.$store.state.idgame;
    },
    storeIdgame() {
      return this.$store.state.storeIdgame;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
  },
  methods: {
    fetchData() {
      let object = {
        liveId: (this.idgame != null ? this.idgame : this.storeIdgame),
        players: this.paramdata,
      };
      console.log(object);
      console.log(this.paramdata);
      for (let x = 0; x < this.paramdata.length; x++) {
        if (
          this.paramdata[x].isOppositeTeam != "" &&
          this.paramdata[x].jerseyNum != ""
        ) {
          axios
            // #NEWZSCORE            .post(process.env.VUE_APP_API_URL + "/radarf2f", object)
            .post(process.env.VUE_APP_API_URL + "/radarf2fz", object, this.headerConfig)
            .then((response) => {
              this.dataradar = response.data.map((v, i) => {
                //Devo mantenere la cardinalità azzerando i valori perchè altrimenti si sballano i colori
                let newValue = v;
                if (this.paramdata[i].isOppositeTeam == "")
                  for (const property in v) {
                    newValue[property] = -3;
                  }
                return newValue;
              });

              console.log(this.dataradar);
              this.Radar(this.dataradar);
            });
          break;
        }
      }
    },
    Radar(data) {
      // let data = [];
      // let dataFull = this.dataradar.map(e => {
      //   for (let i = 0; i < e.values.length; i++) {
      //     return e.values[i];
      //   }
      // });
      // console.log(dataFull);
      let keys = Object.keys(data[0]);
      console.log(keys);
      let features = keys;
      // generate the data
      // for (var i = 0; i < 4; i++) {
      //   var point = {};
      //   //each feature will be a random number from 1-9
      //   //features.forEach(f => (point[f] = 1 + Math.random() * 15));
      //   features.forEach((f, index) => (point[f] = dataFull[index]));
      //   data.push(point);
      // }
      console.log(data);

      let size = 600;

      let svg = d3
        .select(".Radar")
        .append("svg")
        .attr("width", size)
        .attr("height", size);

      // #NEWZSCORE       let radialScale = d3.scaleLinear().domain([0, 15]).range([0, 250]);
      let radialScale = d3
        .scaleLinear()
        .domain([-3, +3])
        .range([0, 250]);

      //#NEWZSCORE let ticks = [2, 4, 6, 8, 10, 12, 14, 16];
      let ticks = [-3, -2, -1, 0, 1, 2, 3];
      ticks.forEach((t) =>
        svg
          .append("circle")
          .attr("cx", size / 2)
          .attr("cy", size / 2)
          .attr("fill", "none")
          .attr("stroke", "white")
          .attr("stroke-width", 0.2)
          .attr("r", radialScale(t))
      );
      ticks.forEach((t) =>
        svg
          .append("text")
          .attr("x", size / 2 + 5)
          .attr("y", size / 2 - radialScale(t))
          .text(t.toString())
          .attr("style", "transform: translateX(-3px) translateY(16px)")
          .style("fill", "#969696")
          .attr("font-size", "12px")
      );

      function angleToCoordinate(angle, value) {
        let x = Math.cos(angle) * radialScale(value);
        let y = Math.sin(angle) * radialScale(value);
        return { x: 300 + x, y: 300 - y };
      }

      for (var i = 0; i < features.length; i++) {
        let ft_name = features[i];
        let angle = Math.PI / 2 + (2 * Math.PI * i) / features.length;
        let line_coordinate = angleToCoordinate(angle, 3);
        let label_coordinate = angleToCoordinate(angle, 3.5);

        //draw axis dot
        svg
          .append("circle")
          .attr("cx", line_coordinate.x)
          .attr("cy", line_coordinate.y)
          .attr("r", 2)
          .attr("fill", "white")
          .attr("opacity", 1);

        //draw axis line
        svg
          .append("line")
          .attr("x1", size / 2)
          .attr("y1", size / 2)
          .attr("stroke", "white")
          .attr("stroke-width", 0.9)
          .attr("x2", line_coordinate.x)
          .attr("y2", line_coordinate.y)
          .attr("stroke", "gray");

        //Plot data

        //draw axis label
        svg
          .append("text")
          .attr("x", label_coordinate.x)
          .attr("y", label_coordinate.y)
          .text(ft_name)
          .style("fill", "white")
          .style("transform", "translateX(-5px)")
          .attr("font-size", "12px");
      }

      let line = d3
        .line()
        .x((d) => d.x)
        .y((d) => d.y);
      let colors = ["#00FC77", "#ff2858", "#00ffff", "#ff00ff"];

      // SHADOW
      function angleToCoordinateShadow(angle, value) {
        let x = Math.cos(angle) * radialScale(value);
        let y = Math.sin(angle) * radialScale(value);
        return { x: 310 + x, y: 310 - y };
      }
      function getPathCoordinatesShadow(data_point) {
        let coordinates = [];
        for (var i = 0; i < features.length; i++) {
          let ft_name = features[i];
          let angle = Math.PI / 2 + (2 * Math.PI * i) / features.length;
          coordinates.push(angleToCoordinateShadow(angle, data_point[ft_name]));
        }
        let ft_name = features[0];
        let angle = Math.PI / 2 + (2 * Math.PI * 0) / features.length;
        coordinates.push(angleToCoordinateShadow(angle, data_point[ft_name]));
        return coordinates;
      }

      var defs = svg.append("defs");
      //line
      var filter = defs.append("filter").attr("id", "glowShadow");
      filter
        .append("feGaussianBlur")
        .attr("stdDeviation", "5.5")
        .attr("result", "coloredBlur");
      var feMerge = filter.append("feMerge");
      feMerge.append("feMergeNode").attr("in", "coloredBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");

      for (var i = 0; i < data.length; i++) {
        let d = data[i];
        let color = colors[i];
        let coordinates = getPathCoordinatesShadow(d);

        // draw the path element
        svg
          .append("path")
          .datum(coordinates)
          .attr("d", line)
          .style("filter", "url(#glowShadow)")
          .attr("stroke-width", 8)
          .attr("stroke", "black")
          .attr("fill", "none")
          .attr("stroke-opacity", 1)
          .attr("opacity", 0.4);
      }

      // LINES
      function getPathCoordinates(data_point) {
        let coordinates = [];
        for (var i = 0; i < features.length; i++) {
          let ft_name = features[i];
          let angle = Math.PI / 2 + (2 * Math.PI * i) / features.length;
          coordinates.push(angleToCoordinate(angle, data_point[ft_name]));
        }
        let ft_name = features[0];
        let angle = Math.PI / 2 + (2 * Math.PI * 0) / features.length;
        coordinates.push(angleToCoordinate(angle, data_point[ft_name]));
        return coordinates;
      }

      var defs = svg.append("defs");
      //line
      var filter = defs.append("filter").attr("id", "glow");
      filter
        .append("feGaussianBlur")
        .attr("stdDeviation", "5.5")
        .attr("result", "coloredBlur");
      var feMerge = filter.append("feMerge");
      feMerge.append("feMergeNode").attr("in", "coloredBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");

      for (var i = 0; i < data.length; i++) {
        let d = data[i];
        let color = colors[i];
        let coordinates = getPathCoordinates(d);

        // draw the path element
        svg
          .append("path")
          .datum(coordinates)
          .attr("d", line)
          .style("filter", "url(#glow)")
          .attr("stroke-width", 3)
          .attr("stroke", color)
          .attr("fill", "none")
          .attr("stroke-opacity", 1)
          .attr("opacity", 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Radar {
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
