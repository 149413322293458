var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "addPalyer",
      attrs: {
        width: "125px",
        height: "32px",
        viewBox: "0 0 125 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      },
      on: {
        click: function($event) {
          return _vm.choosePlayer()
        }
      }
    },
    [
      _c("title", [_vm._v("Card player/add player")]),
      _c(
        "g",
        {
          attrs: {
            id: "Symbols",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Card-player/complete",
                transform: "translate(-48.000000, -89.000000)"
              }
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Group",
                    transform: "translate(48.000000, 89.000000)"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M116.631948,31 C117.280569,31 117.843373,30.5429111 117.984074,29.9007618 L123.966356,2.71003843 C124.158946,1.83327715 123.500346,1 122.613232,1 L8.50881323,1 C7.74044674,1 7.07585962,1.54001214 6.90821603,2.30047866 L1.03969224,28.9774826 C0.812175934,30.015034 1.59151909,31 2.64128732,31 L116.631948,31 Z",
                      id: "Stroke-1",
                      stroke: _vm.color
                    }
                  }),
                  _c(
                    "text",
                    {
                      attrs: {
                        id: "ADD-PLAYER",
                        "fill-rule": "nonzero",
                        "font-family": "Helvetica",
                        "font-size": "12",
                        "font-weight": "normal"
                      }
                    },
                    [
                      _c("tspan", { attrs: { x: "23.9873047", y: "21" } }, [
                        _vm._v("ADD PLAYER")
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }