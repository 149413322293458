<template>
<v-app>
  <div class="facetoface">
    <Header />
    <div class="facetoface_container">
      <div class="facetoface_container:left">
        <CardPlayer :id="0" :color="'#00FC77'" />
        <CardPlayer :id="1" :color="'#ff2858'" />
      </div>
      <div class="facetoface_container:graph">
        <img v-if="appStyle != 'stats'"
          class="facetoface_container:graph_data"
          src="~@/assets/images/faceto/data.png"
          alt
        />
        <Radar v-if="radar" :key="count" :paramdata="playersdataRadar" />
        <TemporalGraph
          v-if="!radar"
          :key="count"
          class="facetoface_temporal"
          :paramdata="playersdataTemp"
        />
      </div>
      <div class="facetoface_container:right">
        <CardPlayer :id="2" :color="'#00ffff'" />
        <CardPlayer :id="3" :color="'#ff00ff'" />
      </div>
    </div>
    <SelectPlayer :players="players" :multiple="true" />
    <SelectGraphParameter/>
    <Connection />
    <Menu />
  </div>
</v-app>
</template>

<script>
import Header from "@/components/general/header/Header";
import CardPlayer from "@/components/faceto/cardplayer/CardPlayer";
import SelectPlayer from "@/components/faceto/selectplayer/SelectPlayer.vue";
import Radar from "@/components/general/graph/Radar.vue";
import Connection from "@/components/connection/Connection.vue";
import Menu from "@/components/general/nav/menu.vue";
import SelectGraphParameter from "@/components/faceto/selectgraphparameter/SelectGraphParameter.vue";
export default {
  data() {
    return {
      count: 0
    };
  },
  watch: {
    playersdataTemp(val) {
      this.count += 1;
      console.log(val);
    },
    graphUpd(val) {
      this.count = val;
    },
  },
  methods: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  computed: {
    players() {
      return this.$store.state.players.players;
    },
    temporal() {
      return this.$store.state.faceto.temporal;
    },
    radar() {
      return this.$store.state.faceto.radar;
    },
    playersdataTemp() {
      return this.$store.state.faceto.players.map((e) => {
        return {
          jerseyNum: e.jerseyNum,
          isOppositeTeam: e.isOppositeTeam,
          color: e.color,
        };
      });
    },
    playersdataRadar() {
      return this.$store.state.faceto.players.map((e) => {
        return {
          isOppositeTeam: e.isOppositeTeam,
          jerseyNum: e.jerseyNum,
        };
      });
    },
    graphUpd() {
      return this.$store.state.faceto.updCnt;
    },
    appStyle() {
      return this.$store.state.appStyle;
    },
  },
  components: {
    Header,
    CardPlayer,
    SelectPlayer,
    Radar,
    Connection,
    Menu,
    TemporalGraph: () => import("@/components/general/graph/TemporalLine.vue"),
    SelectGraphParameter,
  },
  created() {
    if (!this.loggedIn()) {
      this.$router.push("/login");
    }
  },
};
</script>

<style lang="scss" scoped>
.facetoface {
  &_container {
    padding: 0 81px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &\:graph {
      width: 700px;
    }
    &\:left,
    &\:right {
      width: 220px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &\:left {
    }
    &\:right {
      // justify-content: flex-end;
    }
    &\:graph {
      position: relative;
      &_data {
        top: 40px;
        left: 40px;
        position: absolute;
      }
    }
  }
  &_temporal {
    margin-top: 160px;
  }
}
</style>