var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "fullpanel", staticClass: "Select" }, [
    _c("div", { ref: "insidepanel", staticClass: "Select_box" }, [
      _vm.appStyle == "stats"
        ? _c("img", {
            staticClass: "setparameters_box:close",
            attrs: {
              src: require("@/assets/images/general/x_stats.svg"),
              alt: ""
            },
            on: { click: _vm.closePanel }
          })
        : _c("img", {
            staticClass: "setparameters_box:close",
            attrs: { src: require("@/assets/images/general/x.svg"), alt: "" },
            on: { click: _vm.closePanel }
          }),
      _c("div", { staticClass: "Select_title" }, [_vm._v("Select Player")]),
      _c("div", { staticClass: "Select_container" }, [
        _c(
          "div",
          [
            _c("div", { staticClass: "Select_container:title" }, [
              _vm._v("Home")
            ]),
            _vm._l(_vm.home, function(val, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "Select_container:details",
                  on: {
                    click: function($event) {
                      return _vm.getPlayer(val.jerseyNum, val.isOppositeTeam)
                    }
                  }
                },
                [_vm._v(_vm._s(val.jerseyNum) + " " + _vm._s(val.name))]
              )
            }),
            _c(
              "div",
              {
                staticClass: "Select_container:details",
                on: {
                  click: function($event) {
                    return _vm.getPlayer("T", false)
                  }
                }
              },
              [_vm._v("Team")]
            )
          ],
          2
        ),
        _c(
          "div",
          [
            _c("div", { staticClass: "Select_container:title" }, [
              _vm._v("Away")
            ]),
            _vm._l(_vm.away, function(val, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "Select_container:details",
                  on: {
                    click: function($event) {
                      return _vm.getPlayer(val.jerseyNum, val.isOppositeTeam)
                    }
                  }
                },
                [_vm._v(_vm._s(val.jerseyNum) + " " + _vm._s(val.name))]
              )
            }),
            _c(
              "div",
              {
                staticClass: "Select_container:details",
                on: {
                  click: function($event) {
                    return _vm.getPlayer("T", true)
                  }
                }
              },
              [_vm._v("Team")]
            )
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }