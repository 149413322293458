// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/general/sfondo.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-ef552144]:root {\n  --background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  --bg-panel-color: rgba(0, 0, 0, 0.5);\n  --background-color: none;\n  --color: white;\n  --border: 1px solid #fdf800;\n  --font-family: Helvetica, Arial, sans-serif;\n  --hb-stroke: #fdf800;\n  --hb-fill: #ffffff;\n  --hb-fill-reverse: black;\n  --setview-width: 650px;\n  --setview-text-align: center;\n  --player-border: 1px solid rgba(255, 255, 255, 0.3);\n  --setparametes-color: rgb(117, 117, 117);\n  --timer-bg-color: #fdf800;\n  --table-border-bottom: 2px solid #fdf800;\n  --login-external-border: none;\n  --login-input-border: 1px solid white;\n  --login-input-width: 80%;\n  --selectplayers-bg-color: #fdf800;\n}\n[theme=stats][data-v-ef552144] {\n  --background-image: none;\n  --bg-panel-color: #f2f2f2;\n  --background-color: #f2f2f2;\n  --color: #222222;\n  --border: 1px solid #020202;\n  --font-family: Roboto, Helvetica, Arial, sans-serif ;\n  --hb-stroke: #020202;\n  --hb-fill: #020202;\n  --hb-fill-reverse: #f2f2f2;\n  --setview-width: 360px;\n  --setview-text-align: left;\n  --setview-background-color: #ffffff;\n  --player-border: 1px solid #222222;\n  --setparametes-color: #f2f2f2;\n  --timer-bg-color: #222222;\n  --table-border-bottom: 2px solid #222222;\n  --login-external-border: 1px solid #020202;\n  --login-input-border: 1px solid #020202;\n  --login-input-width: 100%;\n  --selectplayers-bg-color: #f2f2f2;\n}\n.Radar[data-v-ef552144] {\n  height: 75vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}", ""]);
// Exports
module.exports = exports;
